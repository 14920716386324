<script>
</script>

<div class="not-implemented">
  <div class="title">페이지 공사 중...</div>
  <div class="description">이 페이지는 개발자가 열심히 작업 중이에요.</div>
</div>

<style lang="scss">
  .not-implemented {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px;
    margin-top: 50px;

    .title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .description {
      font-size: 16px;
      color: #666;
    }
  }
</style>
