<script>
</script>

<div class="page-menu">
  <slot />
</div>

<style lang="scss">
  @import "../styles/variables.scss";

  .page-menu {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 0;
  }
</style>
