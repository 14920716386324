<script>
  import MainContentLayout from "../layouts/MainContentLayout.svelte";
  import "./PrivacyPolicy.scss";
</script>

<MainContentLayout>
  <div class="privacy-policy">
    <div class="title">Privacy Policy</div>
    <div class="section">
      <div class="title">1. Information We Collect</div>
      <div class="raw">
        <div class="line">We may collect the following types of personal information:</div>
        <div class="dot">Required Information: e.g., general information from riot account, etc.</div>
        <div class="dot">Optional Information: e.g., rso user data from riot RSO, etc.</div>
        <div class="dot">Automatically Collected Information: e.g., cookies, access logs, match history, etc.</div>
      </div>
    </div>
    <div class="section">
      <div class="title">2. Purpose of Collecting Information</div>
      <div class="raw">
        <div class="line">The personal information we collect is used for the following purposes:</div>
        <div class="dot">User Management: e.g., verifying service usage, producing enhanced system & service, etc.</div>
        <div class="dot">Service Provision: e.g., providing personalized content, calculated information, etc.</div>
      </div>
    </div>
    <div class="section">
      <div class="title">3. Sharing of Information</div>
      <div class="raw">
        <div class="line">
          We do not share your personal information with third parties without your consent, except in the following
          cases:
        </div>
        <div class="dot">When required by law</div>
        <div class="dot">When it is necessary to provide our services (e.g., payment processing)</div>
      </div>
    </div>
    <div class="section">
      <div class="title">4. Retention of Personal Information</div>
      <div class="raw">
        <div class="line">
          We retain collected personal information for as long as necessary to fulfill the purposes for which it was
          collected. However, we may retain information for longer periods as required by law.
        </div>
      </div>
    </div>
    <div class="section">
      <div class="title">5. Your Rights</div>
      <div class="raw">
        <div class="line">
          You have the right to access, modify, or delete your personal information at any time. You may also request to
          withdraw your consent. Please contact us for any requests.
        </div>
      </div>
    </div>
    <div class="section">
      <div class="title">6. Use of Cookies</div>
      <div class="raw">
        <div class="line">
          We may use cookies to enhance user experience. You can manage or disable cookies through your browser
          settings.
        </div>
      </div>
    </div>
    <div class="section">
      <div class="title">7. Contact for Privacy Inquiries</div>
      <div class="raw">
        <div class="line">
          If you have any questions or concerns about our Privacy Policy, please contact us at whdudgns7321@gmail.com
        </div>
      </div>
    </div>
    <div class="section">
      <div class="title">8. Changes to This Privacy Policy</div>
      <div class="raw">
        <div class="line">
          We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we encourage
          you to review it periodically.
        </div>
      </div>
    </div>
  </div>
</MainContentLayout>
