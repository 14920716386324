<script>
  import MainContentLayout from "../layouts/MainContentLayout.svelte";
  import "./PrivacyPolicy.scss";
</script>

<MainContentLayout>
  <div class="terms-of-service">
    <div class="title">Terms of Service</div>

    <div class="section">
      <div class="title">1. Definitions</div>
      <div class="raw">
        <div class="line">"Service" refers to team.gg provided by shyunku.</div>
        <div class="line">"User" refers to anyone who accesses or uses the Service.</div>
      </div>
    </div>

    <div class="section">
      <div class="title">2. Eligibility</div>
      <div class="raw">
        <div class="line">To use our Service, you must be at least 12 years old and comply with applicable laws.</div>
      </div>
    </div>

    <div class="section">
      <div class="title">3. User Responsibilities</div>
      <div class="raw">
        <div class="line">You agree not to engage in the following activities while using the Service:</div>
        <div class="dot">Illegal activities or violations of applicable laws.</div>
        <div class="dot">Distributing harmful, offensive, or inappropriate content.</div>
        <div class="dot">Violating the rights of others or interfering with the Service.</div>
      </div>
    </div>

    <div class="section">
      <div class="title">4. Intellectual Property</div>
      <div class="raw">
        <div class="line">All content and software provided by team.gg are the property of shyunku.</div>
        <div class="line">You may not copy, modify, or distribute any part of the Service without our permission.</div>
      </div>
    </div>

    <div class="section">
      <div class="title">5. Limitation of Liability</div>
      <div class="raw">
        <div class="line">
          team.gg is provided "as is." We do not guarantee that the Service will be error-free or uninterrupted.
        </div>
        <div class="line">We are not responsible for any damages that may result from your use of the Service.</div>
      </div>
    </div>

    <div class="section">
      <div class="title">6. Termination</div>
      <div class="raw">
        <div class="line">
          We reserve the right to suspend or terminate your access to the Service if you violate these terms.
        </div>
      </div>
    </div>

    <div class="section">
      <div class="title">7. Changes to the Service</div>
      <div class="raw">
        <div class="line">
          We may modify or discontinue any part of the Service at any time, with or without notice.
        </div>
      </div>
    </div>

    <div class="section">
      <div class="title">8. Governing Law</div>
      <div class="raw">
        <div class="line">
          These terms are governed by the laws of Republic of Korea, and any disputes will be resolved under the courts
          of Republic of Korea.
        </div>
      </div>
    </div>

    <div class="section">
      <div class="title">9. Modifications to These Terms</div>
      <div class="raw">
        <div class="line">
          We may update these terms from time to time. Continued use of the Service implies acceptance of the revised
          terms.
        </div>
      </div>
    </div>

    <div class="section">
      <div class="title">10. Contact Information</div>
      <div class="raw">
        <div class="line">For any questions about these terms, please contact us at whdudgns7321@gmail.com</div>
      </div>
    </div>
  </div>
</MainContentLayout>
